import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import { createI18n } from 'vue-i18n'

import '@/core/permission' // permission control

const app = createApp(App)
app.component('QuillEditor', QuillEditor)
// for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
//   app.component(key, component)
// }
const i18n = createI18n({
  locale: 'zh-Tw',
  fallbackLocale: 'zh-Cn'
})

app.use(ElementPlus)
app.use(router)
app.use(i18n)
app.mount('#app')
