import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'login',
    meta: { title: '登录' },
    component: () => import('../views/login/loginIndex.vue')
  },
  {
    path: '/register',
    name: 'register',
    meta: { title: '注册' },
    component: () => import('../views/login/registerA.vue')
  },
  {
    path: '/layout',
    name: 'layout',
    redirect: '/main',
    meta: { title: '首页' },
    component: () => import('../views/Layout/layoutIndex.vue'),
    children: [
      {
        path: '/main',
        name: 'main',
        meta: { title: '首页' },
        component: () => import('@/views/index/aIndex.vue')
      },
      {
        path: '/list',
        name: 'list',
        meta: { title: '通讯录' },
        component: () => import('@/views/index/addressList.vue')
      },
      {
        path: '/box',
        name: 'box',
        meta: { title: '收件箱' },
        component: () => import('@/views/index/mailBox.vue')
      },
      {
        path: '/write',
        name: 'write',
        meta: { title: '写信' },
        component: () => import('@/views/index/mailWrite.vue')
      },
      {
        path: '/change',
        name: 'change',
        meta: { title: '常规设置' },
        component: () => import('@/views/index/myUpdate.vue')
      },
      {
        path: '/setting',
        name: 'setting',
        meta: { title: '修改密码' },
        component: () => import('@/views/index/mySetting.vue')
      },
      {
        path: '/user',
        name: 'user',
        meta: { title: '用户管理' },
        component: () => import('@/views/index/myUser.vue')
      }
    ]
  },
  {
    path: '/404',
    name: '404',
    meta: { title: '404' },
    component: () => import('../views/public/404.vue')
  },
  {
    path: '/405',
    name: '405',
    meta: { title: '405' },
    component: () => import('../views/public/405.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
