import request from '@/core/request'

export const login = (email, password) => {
  return request({
    url: '/auth/login',
    method: 'post',
    params: {
      email, password
    }
  })
}

export const logout = () => {
  return request({
    url: '/auth/logout',
    method: 'post'
  })
}

// 注册
export const regist = (email, password, name) => {
  return request({
    url: '/auth/regist',
    method: 'post',
    data: {
      email, password, name
    }
  })
}

export const info = () => {
  return request({
    url: '/auth/info',
    method: 'post'
  })
}

// 修改密码
export const updatePassword = (password) => {
  return request({
    url: '/user/updatePassword',
    method: 'post',
    data: {
      password
    }
  })
}

// 修改用户资料
export const updateUserInfo = (name, avatar) => {
  console.log('api', name, avatar)

  return request({
    url: '/user/updateMine',
    method: 'post',
    data: {
      name, avatar
    }
  })
}

// 添加联系人
export const addContact = () => {
  return request({
    url: '/add/contact',
    method: 'post'
  })
}

// 删除联系人
export const deleteContact = () => {
  return request({
    url: '/delete/contact',
    method: 'post'
  })
}

// 查询联系人列表
export const listContact = () => {
  return request({
    url: '/user/contact',
    method: 'post'
  })
}
// 查询联系人列表
export const searchUser = (email) => {
  return request({
    url: '/user/search',
    method: 'post',
    data: { email: `%${email}%` }
  })
}

// 查询用户列表
export const listUser = (data) => {
  return request({
    url: '/user/list',
    method: 'post',
    data
  })
}

// 禁用用户
export const forbiddenUser = (data) => {
  return request({
    url: '/user/forbidden',
    method: 'post',
    data
  })
}

// 将用户加入黑名单
export const addBlackUser = (data) => {
  return request({
    url: '/black/add',
    method: 'post',
    data
  })
}

// 将用户移出黑名单
export const deleteBlackUser = (data) => {
  return request({
    url: '/black/delete',
    method: 'post',
    data
  })
}
// 删除用户
export const deleteUser = (data) => {
  return request({
    url: '/user/delete',
    method: 'post',
    data
  })
}

// 修改用户邮件限制
export const updateUserLimit = (data) => {
  return request({
    url: '/user/limit',
    method: 'post',
    data
  })
}

// 开关用户服务
export const updateUserService = (data) => {
  return request({
    url: '/user/service',
    method: 'post',
    data
  })
}

// 新增用户
export const addUser = (data) => {
  return request({
    url: '/user/add',
    method: 'post',
    data
  })
}
