import router from '@/router'
import { info, login } from '@/api/user'
// import caches from '@/core/caches.js'
import { ElMessage } from 'element-plus'
// import auth from '@/core/auth.js'

const whiteList = ['/404', '/', '/405', '/register'] // 不重定向白名单

router.beforeEach((to, from, next) => {
  if (whiteList.indexOf(to.path) !== -1) {
    // auth.logout()
    // window.localStorage.removeItem('userInfo')
    next()
  } else if (from.path === to.path) {
    return
  } else {
    const userinfoStr = window.localStorage.getItem('userInfo')
    const userinfo = userinfoStr ? JSON.parse(userinfoStr) : {}
    if (userinfo && userinfo.email) {
      info().then(() => {
        next()
      }).catch(() => {
        login(userinfo.email, userinfo.pwd).then(() => {
          ElMessage({
            message: '自动登录成功！',
            type: 'success'
          })
          next()
        }).catch(() => {
          next('/')
        })
      })
    } else {
      next('/')
    }
  }
  if (to.meta.title) {
    document.title = to.meta.title
  }
})
