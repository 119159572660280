import axios from 'axios'
import { ElMessage } from 'element-plus'
import caches from './caches'
import auth from './auth'
import config from '@/config'
import router from '@/router'

// create an axios instance
const service = axios.create({
  baseURL: config.BASE_URL, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: config.REQUEST_TIMEOUT // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    config.headers['content-type'] = 'application/json;charset=UTF-8'

    if (config.url.indexOf('login') === -1 && caches.jtoken) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers.jtoken = caches.jtoken
    }
    // if (config.url.indexOf('118.31.5.58') !== -1 && caches.userinfo) {
    //   console.error('修改Access-Control-Allow-Origin');
    //   config.headers['Access-Control-Allow-Origin'] = 'http://118.31.5.58:18080'
    // }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

function t (str) {
  const cs = '{}[].,:?!\'"-_ 0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ！\\//+=~@#$%^&*()`;<>'
  let res = ''
  let hex
  for (let i = 0; i < str.length; i++) {
    if (cs.includes(str[i])) {
      res += str[i]
    } else if (str.charCodeAt(i) >= 0x4e00 && str.charCodeAt(i) <= 0x9fa5) {
      res += str[i]
    } else {
      hex = '0' + str.charCodeAt(i).toString(16)
      res += '(0x' + hex.substring(hex.length - 2, hex.length) + ')'
    }
  }
  return res
}

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    if (response.headers.jtoken) {
      caches.jtoken = response.headers.jtoken
    }
    if (typeof response.data === 'string') {
      response.data = JSON.parse(t(response.data))
    }
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (res.data === '{}') {
      res.data = {}
    }
    if (res.code !== 20000) {
      if (res.code === 401) {
        ElMessage({
          message: res.message,
          type: 'error',
          duration: 3 * 1000
        })
        // auth.logout()
        router.replace('/')
        return
      } else if (res.code === 520) {
        ElMessage({
          message: res.message,
          type: 'error',
          duration: 4 * 1000
        })
        // auth.logout()
        router.replace('/')
        return
      } else if (res.code === 100) {
        ElMessage({
          message: res.message,
          type: 'warning',
          duration: 4 * 1000
        })
        return
      } else if (res.code === 500) {
        console.log('500', res)
        ElMessage({
          message: res.message,
          type: 'error',
          duration: 4 * 1000
        })
        return Promise.reject(new Error(res.message || 'Error'))
      }
      ElMessage({
        message: res.message || 'Error',
        type: 'error',
        duration: 4 * 1000
      })

      return Promise.reject(new Error(res.data || 'Error'))
    } else {
      return res
    }
  },
  error => {
    if (!error || !error.response) {
      return Promise.reject(error)
    }
    if (error.response.status === 50008 || error.response.status === 50012 || error.response.status === 50014) {
      // re-login
      auth.logout()
    } else {
      ElMessage({
        message: error.message,
        type: 'error',
        duration: 4 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export default service
