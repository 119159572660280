import { login, logout, info } from '../api/user'
import { ElMessage } from 'element-plus'
import caches from './caches'
// import router from './router'

const auth = {
  user: {},
  loginInfo: {
    username: undefined,
    password: undefined
  },
  init () {
    this.loginInfo = caches.loginInfo
    this.user = caches.userinfo
  },
  verify () {
    return new Promise((resolve, reject) => {
      info().then(resp => {
        caches.userinfo = resp.data
        this.user = resp.data
        resolve(resp.data)
      }).catch(() => {
        this.login().then(resp => {
          caches.userinfo = resp.data
          this.user = resp.data
          ElMessage({
            message: '自动登录成功！',
            type: 'success',
            duration: 5 * 1000
          })
          resolve(resp)
        }).catch(e => {
          confirm('此功能需要登录，请前往登录！')
          // router.to('login')
          this.$router.push({ path: '/' })
          reject(e)
        })
      })
    })
  },
  login (obj) {
    return new Promise((resolve, reject) => {
      if (obj && obj.username && obj.password) {
        caches.loginInfo = {
          username: obj.username,
          password: obj.password
        }
      }
      login(caches.loginInfo.username, caches.loginInfo.password, '1').then(resp => {
        caches.userinfo = resp.data
        if (resp.data.token) {
          caches.jtoken = resp.data.token
        }
        if (resp.data.roleId === '3') {
          alert('您没有权限登录！')
          // reject('您没有权限登录！')
        }
        resolve(resp)
      }).catch(e => {
        reject(e)
      })
    })
  },
  logout () {
    return new Promise((resolve, reject) => {
      logout(caches.jtoken).then(() => {
        caches.userinfo = undefined
        caches.loginInfo = undefined
        caches.autoLogin = false
        caches.jtoken = undefined
        window.localStorage.removeItem('Jsessionid')
        resolve()
      }).catch(e => {
        reject(e)
      })
    })
  }
}

export default auth
